import * as React from "react"

import Layout from '../components/layout'
import Product from '../components/item'

// markup
const Other = () => {
  return (
    <Layout>
      Other
    </Layout>
  )
}

export default Other
